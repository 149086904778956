import {AjaxPromise} from "../../shared/ajax_utilities";

export class TPLBase {
    GRID_ID;

    constructor(gridId) {
        this.GRID_ID = gridId;
        app.DASHBOARD_CORE.TPL_CLASSES[gridId] = this;
    }

    async setup() {
        if(!app.OPTIONS.gridstack_allowed || !app.OPTIONS.gridstack_allowed[this.GRID_ID]) {
            console.warn(`Unable to find gridstack options for ${this.GRID_ID} and is unable to setup tpl`);
            return;
        }
        const opts = app.OPTIONS.gridstack_allowed[this.GRID_ID];
        if(!opts.ajax_tpl) {
            console.warn(`Unable to setup ${this.GRID_ID} tpl`);
            return;
        }

        try {
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
                method: 'POST',
                data: {
                    dashboard: app.DASHBOARD_CORE.DASHBOARD,
                    allowed: app.DASHBOARD_CORE.getDataGridstack(),
                }
            });
            app.DASHBOARD_CORE.paintTpl(app.OPTIONS.gridstack_allowed[this.GRID_ID].ajax_tpl, res, this.GRID_ID);
        } catch(err) {
            console.error(`Unable to setup ${this.GRID_ID}`, err);
        }
    }

    async update() {
        try {
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
                method: 'POST',
                data: {
                    dashboard: app.DASHBOARD_CORE.DASHBOARD,
                    allowed: app.DASHBOARD_CORE.getDataGridstack(),
                }
            });
            app.DASHBOARD_CORE.paintTpl(app.OPTIONS.gridstack_allowed[this.GRID_ID].ajax_tpl, res, this.GRID_ID);
        } catch(err) {
            console.log(err);
        }
    }
}